export enum UnityTypes {
  G = "g",
  ML = "ml",
  SPOON = "cucchiaio",
  LITTLE_SPOON = "cucchiaino",
  GLASS = "bicchiere",
  CUP = "tazza",
  JAR = "vasetto",
  MEDIUM_FRUIT = "frutto medio",
  HANDFUL = "manciata",
  FIST = "pugno",
  SLICE = "fetta",
  PALM = "palmo",
  FINGER = "dito",
  BISCUIT = "biscotto",
  RUSK = "fetta biscottata",
  POTATO = "patata",
  EGG = "uovo",
  PLATE = "piatto",
}
export const UnitConverter = {
  [UnityTypes.G]: 1,
  [UnityTypes.ML]: 1,
  [UnityTypes.LITTLE_SPOON]: 5,
  [UnityTypes.SPOON]: 10,
  [UnityTypes.GLASS]: 200,
  [UnityTypes.CUP]: 250,
  [UnityTypes.JAR]: 125,
  [UnityTypes.MEDIUM_FRUIT]: 150,
  [UnityTypes.HANDFUL]: 40,
  [UnityTypes.FIST]: 70,
  [UnityTypes.SLICE]: 50,
  [UnityTypes.PALM]: 100,
  [UnityTypes.FINGER]: 25,
  [UnityTypes.BISCUIT]: 10,
  [UnityTypes.RUSK]: 10,
  [UnityTypes.POTATO]: 100,
  [UnityTypes.EGG]: 50,
  [UnityTypes.PLATE]: 300,
};
