import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Toolbar,
  Typography,
  Button,
  Chip,
  SvgIcon,
  Divider,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import { BasicTooltip } from '@nivo/tooltip'
import { ResponsivePie } from '@nivo/pie'
import Header from "../../../components/Header";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ReactComponent as energy_icon } from "../../../assets/icons/fire.svg";
import { ReactComponent as cook_time_icon } from "../../../assets/icons/control-knobs-emoji.svg";
import { ReactComponent as prep_time_icon } from "../../../assets/icons/spoon-emoji.svg";
import placeholder from "../../../assets/picture.png";
import { useGetRicetteDetailsQuery } from "../../../redux/state/api";
import { ReactComponent as apple } from "../../../assets/ingredient_categories_icons/apple-1.svg";
import { ReactComponent as beans } from "../../../assets/ingredient_categories_icons/beans.svg";
import { ReactComponent as birds } from "../../../assets/ingredient_categories_icons/birds.svg";
import { ReactComponent as biscuit } from "../../../assets/ingredient_categories_icons/biscuit.svg";
import { ReactComponent as bowl } from "../../../assets/ingredient_categories_icons/bowl-1.svg";
import { ReactComponent as bread } from "../../../assets/ingredient_categories_icons/bread.svg";
import { ReactComponent as bread1 } from "../../../assets/ingredient_categories_icons/bread-1.svg";
import { ReactComponent as broccoli } from "../../../assets/ingredient_categories_icons/broccoli.svg";
import { ReactComponent as butter } from "../../../assets/ingredient_categories_icons/butter-emoji 1.svg";
import { ReactComponent as can } from "../../../assets/ingredient_categories_icons/can.svg";
import { ReactComponent as canned_fruit } from "../../../assets/ingredient_categories_icons/canned-fruit.svg";
import { ReactComponent as cereals } from "../../../assets/ingredient_categories_icons/cereals.svg";
import { ReactComponent as cheese } from "../../../assets/ingredient_categories_icons/cheese.svg";
import { ReactComponent as chips } from "../../../assets/ingredient_categories_icons/chips.svg";
import { ReactComponent as chocolate } from "../../../assets/ingredient_categories_icons/chocolate.svg";
import { ReactComponent as coffee } from "../../../assets/ingredient_categories_icons/coffee.svg";
import { ReactComponent as cookies } from "../../../assets/ingredient_categories_icons/cookies.svg";
import { ReactComponent as corn } from "../../../assets/ingredient_categories_icons/corn.svg";
import { ReactComponent as eggs } from "../../../assets/ingredient_categories_icons/eggs.svg";
import { ReactComponent as fish } from "../../../assets/ingredient_categories_icons/fish.svg";
import { ReactComponent as flour } from "../../../assets/ingredient_categories_icons/flour-1.svg";
import { ReactComponent as food_additives } from "../../../assets/ingredient_categories_icons/food-additives.svg";
import { ReactComponent as frozen } from "../../../assets/ingredient_categories_icons/frozen.svg";
import { ReactComponent as glass } from "../../../assets/ingredient_categories_icons/glass-3.svg";
import { ReactComponent as gluten_free } from "../../../assets/ingredient_categories_icons/gluten-free.svg";
import { ReactComponent as grain } from "../../../assets/ingredient_categories_icons/grain.svg";
import { ReactComponent as ham } from "../../../assets/ingredient_categories_icons/ham.svg";
import { ReactComponent as hamburger } from "../../../assets/ingredient_categories_icons/hamburger.svg";
import { ReactComponent as high_protein_foods } from "../../../assets/ingredient_categories_icons/high-protein-foods.svg";
import { ReactComponent as ice_cream } from "../../../assets/ingredient_categories_icons/ice-cream-11.svg";
import { ReactComponent as incomplete } from "../../../assets/ingredient_categories_icons/incomplete.svg";
import { ReactComponent as jam } from "../../../assets/ingredient_categories_icons/jam-1.svg";
import { ReactComponent as liquid_seasonings } from "../../../assets/ingredient_categories_icons/liquid-seasonings.svg";
import { ReactComponent as low_protein_foods } from "../../../assets/ingredient_categories_icons/low-protein-foods.svg";
import { ReactComponent as meat } from "../../../assets/ingredient_categories_icons/meat.svg";
import { ReactComponent as milk } from "../../../assets/ingredient_categories_icons/milk.svg";
import { ReactComponent as muesli } from "../../../assets/ingredient_categories_icons/muesli.svg";
import { ReactComponent as mushroom } from "../../../assets/ingredient_categories_icons/mushroom.svg";
import { ReactComponent as mustard } from "../../../assets/ingredient_categories_icons/mustard-1.svg";
import { ReactComponent as oil } from "../../../assets/ingredient_categories_icons/oil.svg";
import { ReactComponent as orange } from "../../../assets/ingredient_categories_icons/orange.svg";
import { ReactComponent as pasta } from "../../../assets/ingredient_categories_icons/pasta.svg";
import { ReactComponent as pie } from "../../../assets/ingredient_categories_icons/pie-2.svg";
import { ReactComponent as pistachio } from "../../../assets/ingredient_categories_icons/pistachio.svg";
import { ReactComponent as pizza } from "../../../assets/ingredient_categories_icons/pizza.svg";
import { ReactComponent as potatoes } from "../../../assets/ingredient_categories_icons/potatoes-2.svg";
import { ReactComponent as ready_meals } from "../../../assets/ingredient_categories_icons/ready-meals.svg";
import { ReactComponent as risotto } from "../../../assets/ingredient_categories_icons/risotto.svg";
import { ReactComponent as salad } from "../../../assets/ingredient_categories_icons/salad.svg";
import { ReactComponent as salt } from "../../../assets/ingredient_categories_icons/salt.svg";
import { ReactComponent as sandwich } from "../../../assets/ingredient_categories_icons/sandwich.svg";
import { ReactComponent as sausage } from "../../../assets/ingredient_categories_icons/sausage.svg";
import { ReactComponent as seeds } from "../../../assets/ingredient_categories_icons/seeds.svg";
import { ReactComponent as spices } from "../../../assets/ingredient_categories_icons/spices.svg";
import { ReactComponent as steak } from "../../../assets/ingredient_categories_icons/steak.svg";
import { ReactComponent as supplements } from "../../../assets/ingredient_categories_icons/supplements.svg";
import { ReactComponent as tea } from "../../../assets/ingredient_categories_icons/tea-1.svg";
import { ReactComponent as uncategorized } from "../../../assets/ingredient_categories_icons/uncategorized.svg";
import { ReactComponent as vegan } from "../../../assets/ingredient_categories_icons/vegan.svg";
import { ReactComponent as vegetal_drinks } from "../../../assets/ingredient_categories_icons/vegetal-drinks.svg";
import { ReactComponent as water } from "../../../assets/ingredient_categories_icons/water.svg";
import { ReactComponent as yogurt } from "../../../assets/ingredient_categories_icons/yogurt.svg";
import { ReactComponent as zero_drinks } from "../../../assets/ingredient_categories_icons/zero-drinks.svg";

export const ingredient_categories_icons_mapping = {
  1: glass,
  2: water,
  3: can,
  4: coffee,
  5: tea,
  6: biscuit,
  7: steak,
  8: meat,
  9: bowl,
  10: cereals,
  11: sandwich,
  12: pie,
  13: salt,
  14: mustard,
  15: cookies,
  16: chocolate,
  17: jam,
  18: ice_cream,
  19: ice_cream,
  20: apple,
  21: pistachio,
  22: orange,
  23: oil,
  24: cheese,
  25: milk,
  26: cereals,
  27: bread,
  28: bread,
  29: fish,
  30: grain,
  31: flour,
  32: beans,
  33: corn,
  34: pasta,
  35: potatoes,
  36: risotto,
  37: ham,
  38: sausage,
  39: chips,
  40: eggs,
  41: mushroom,
  42: seeds,
  43: broccoli,
  44: salad,
  45: supplements,
  46: mustard - 1,
  47: spices,
  48: yogurt,
  49: hamburger,
  50: frozen,
  51: vegan,
  52: vegetal_drinks,
  53: food_additives,
  54: birds,
  55: zero_drinks,
  56: gluten_free,
  57: canned_fruit,
  58: ready_meals,
  59: high_protein_foods,
  60: low_protein_foods,
  61: pizza,
  62: uncategorized,
  63: incomplete,
}

const pluralUnit = {
  "g": "g",
  "ml": "ml",
  "cucchiaino": "cucchiaini",
  "cucchiaio": "cucchiai",
  "bicchiere": "bicchieri",
  "tazza": "tazze",
  "vasetto": "vasetti",
  "frutto medio": "frutti medi",
  "manciata": "manciate",
  "pugno": "pugni",
  "fetta": "fette",
  "palmo": "palmi",
  "dito": "dita",
  "biscotto": "biscotti",
  "fetta biscottata": "fette biscottate",
  "patata": "patate",
  "uovo": "uova",
  "piatto": "piatti"
}


export const Ingrediente = ({ image, name, specific_info, quantity, categories_id, energy = 0, selected = true, unit = "g" }) => {
  return (
    <Box
      mt="16px"
      width="90%"
      sx={{ display: "flex", justifyContent: "space-between", opacity: !selected ? 0.5 : 1.0 }}
    >
      <Box display="flex" gap="1rem">
        <Box
          sx={{
            width: "32px",
            height: "32px",
            backgroundColor: "#4496A8",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20%",
          }}
        >
          <SvgIcon component={ingredient_categories_icons_mapping[categories_id]} inheritViewBox />
        </Box>
        <Box marginTop="-3px">
          <Typography
            color="#2F1847"
            fontSize="20px"
            fontWeight="700"
            variant="h1"
          >
            {name !== null ? name : "Ingrediente"}
          </Typography>
          <Typography
            color="rgba(193, 199, 205, 1)"
            fontSize="12px"
            fontWeight="700"
            variant="h1"
          >
            {specific_info !== null
              ? specific_info
              : ""}
          </Typography>
        </Box>
      </Box>
      <Box textAlign={"right"}>
        <Typography
          color="#2F1847"
          fontSize="16px"
          fontWeight="400"
          variant="h1"
        >
          {quantity !== null ? quantity : "X"} {quantity == 1 ? unit : pluralUnit[unit]}
        </Typography>
        <Typography
          color="rgba(193, 199, 205, 1)"
          fontSize="12px"
          fontWeight="700"
          variant="h1"
        >
          {energy !== null ? energy : "X"}kcal
        </Typography>
      </Box>
    </Box>
  );
};


const BoxInfo = ({ title, description, icon = null }) => {
  return (
    <Box
      mt="20px"
      width="100px"
      height="100px"
      sx={{
        borderRadius: "6px",
        backgroundColor: "#EDC34C",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "32px",
            height: "32px",
            mt: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "20%",
          }}
        >
          <SvgIcon component={icon} inheritViewBox />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography
          color="#464868"
          fontSize="12px"
          fontWeight="700"
          variant="h1"
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography color="#fff" fontSize="18px" fontWeight="700" variant="h1">
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

const RicettaDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const pathnameParts = location.pathname.split("/");
  const id = pathnameParts[pathnameParts.length - 1];

  const { data, isLoading } = useGetRicetteDetailsQuery(id);

  const handleNavigateBack = () => {
    navigate("/ricette");
  };

  if (isLoading) {
        return <div class="loader-container">
    <div class="loader"></div>
</div>
;
  }

  const value1 = Number(data && data.nutrition_facts.carbohydrates.percentage);
  const value2 = Number(data && data.nutrition_facts.proteins.percentage);
  const value3 = Number(data && data.nutrition_facts.fats.percentage);
  const value4 = Number(data && data.nutrition_facts.alcohol.percentage);
  const value5 = Number(data && data.nutrition_facts.fibers.percentage);
  const value6 = Number(data && data.nutrition_facts.sugars.percentage);

  const data2 = [
    {
      id: "Carbo",
      value: value1,
      label: `Carbo ${data.nutrition_facts.carbohydrates.quantity}g`,
    },
    {
      id: "Proteine",
      value: value2,
      label: `Proteine ${data.nutrition_facts.proteins.quantity}g`,

    },
    {
      id: "Grassi",
      value: value3,
      label: `Grassi ${data.nutrition_facts.fats.quantity}g`,
    },
    {
      id: "Alcol",
      value: value4,
      label: `Alcol ${data.nutrition_facts.alcohol.quantity}g`,
    },
    {
      id: "Zuccheri",
      value: value6,
      label: `Zuccheri ${data.nutrition_facts.sugars.quantity}g`,
    },
    {
      id: "Fibre",
      // value: value5,
      label: `Fibre ${data.nutrition_facts.fibers.quantity}g`,
    },
  ];

  return (
    <Box
      m="1.5rem 2.5rem"
      width="90%"
      sx={{
        overflow: "hidden",
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Button
          sx={{
            color: "#464868",
            textDecoration: "underline",
            fontSize: "14px",
            fontWeight: "500",
          }}
          onClick={handleNavigateBack}
        >
          Torna a "Ricette"
        </Button>
        <Button

          startIcon={<ModeEditIcon />}
          sx={{  background: 'linear-gradient(to top right, #B0D99C, #68BE6A)',
  textTransform: 'none',
  fontWeight: 600,
  padding:"8px 16px",
  color: "white", 
  borderRadius: "8px",
  fontSize: "14px",
  fontWeight: "600",
  boxShadow: 'none',
  '&:hover, &:active, &:focus': {
    boxShadow: 'none'
  }
}}
          onClick={() => navigate(`/ricette/modificaRicetta/${id}`)}
        >
          Modifica
        </Button>
      </Toolbar>
      <Box
        width="100%"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box width="70%">
          <Box ml="20px">
            <Header title={data && data.name} />
            <Box display="flex" gap="1rem">
              {data && data.categories.map(({ name }) => (
                <Typography
                  color="#2F1847"
                  fontSize="20px"
                  fontWeight="700"
                  variant="h3"
                >
                  {name}
                </Typography>
              ))}
            </Box>
            <Box mt="32px">
              <Typography
                color="#2F1847"
                fontSize="16px"
                fontWeight="400"
                variant="body2"
              >
                {data && data.description}
              </Typography>
            </Box>
          </Box>
          <Box ml="20px" mt="32px">
            <Typography
              color="#2F1847"
              fontSize="24px"
              fontWeight="700"
              variant="h1"
            >
              Caratteristiche
            </Typography>
            <Box mt="16px" sx={{ display: "flex", gap: "1rem" }}>
              {data && data.tags.map(({ name }) => (
                <Chip
                  label={name}
                  sx={{ backgroundColor: "rgba(70, 72, 104, 1)", color: "white" }}
                />
              ))}
            </Box>
          </Box>
          <Box ml="20px" mt="60px">
            <Typography
              color="#2F1847"
              fontSize="24px"
              fontWeight="700"
              variant="h1"
            >
              Ingredienti
            </Typography>
            {data && data.ingredients.map(
              ({ simple_name, specific_info, quantity, category, nutrition_facts}) => (
                <Ingrediente
                  name={simple_name}
                  specific_info={specific_info}
                  quantity={quantity}
                  energy={nutrition_facts.energy.quantity}
                  categories_id={category.id}
                />
              )
            )}
            <Divider
              sx={{
                marginTop: "32px",
                width: "80%",
                bgcolor: "transparent", // Imposta il colore di sfondo su trasparente
                border: 0, // Rimuove il bordo predefinito
                borderTop: "1px dashed rgba(193, 199, 205, 0.50)", // Imposta il bordo superiore come tratteggiato
              }}
            />
          </Box>
          <Box ml="20px" mt="50px">
            <Typography
              color="#2F1847"
              fontSize="24px"
              fontWeight="700"
              variant="h1"
            >
              Procedimento
            </Typography>
            <Stepper orientation="vertical" activeStep={-1}>
              {data && data.steps.map(({ description, index }) => (
                <Step
                  key={`recipe_step_${index}`}
                  active
                  sx={{
                    '& .MuiStepLabel-iconContainer .Mui-active': {
                      color: 'rgba(70, 72, 104, 1)', // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: 'white', // circle's number (ACTIVE)
                    },
                  }}
                >
                  <StepLabel>
                    <Typography
                      color="#2F1847"
                      fontSize="16px"
                      fontWeight="400"
                      variant="body2"
                    >
                      {description !== null ? description : ""}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Box>
        <Box width="30%">
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              component="img"
              alt="img"
              src={
                data && data.image !== null && data.image !== "image_string"
                  ? data.image
                  : placeholder
              }
              height="332px"
              width="100%"
              borderRadius="10%"
            ></Box>
          </Box>
          <Box mt="30px" display="flex" justifyContent="flex-start">
            <Box>
              <Typography
                color="#2F1847"
                fontSize="24px"
                fontWeight="700"
                variant="h1"
              >
                Info e valori
              </Typography>
              <Box display="flex">
                <BoxInfo
                  title="Energia"
                  description={`${Math.round(data && data.nutrition_facts.energy.quantity)}kcal`}
                  icon={energy_icon}
                />
                <Box ml="20px" display="flex">
                  <BoxInfo
                    title="Cottura"
                    description={`${data && data.cook_time}min`}
                    icon={cook_time_icon}
                  />
                </Box>
                <Box ml="-6px" display="flex">
                  <BoxInfo
                    title="Preparazione"
                    description={`${data && data.prep_time}min`}
                    icon={prep_time_icon}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt="20px" display="flex" justifyContent="flex-end">
            <Box
              sx={{
                width: "100%",
                height: "332px",
                borderRadius: "6px",
                border: "1px solid rgba(193, 199, 205, 1)",
              }}
            >
              <Box display="flex">
                <Box minWidth="350px" mt="40px" ml="25px" mr="25px">
                  <Typography
                    color="#464868"
                    fontSize="20px"
                    fontWeight="700"
                    variant="h1"
                  >
                    Macronutrienti
                  </Typography>
                </Box>
              </Box>
              <Box marginLeft="10px" height={325} width={325} >
              <ResponsivePie
                colors={["#EDC047", "#4496A8", "#ED8047", "#BF2B2B", "#F2E68A", "#ffffff"]}
                tooltip={e => {
                  let { datum } = e; return <BasicTooltip
                    id={datum.id}
                    value={`${datum.formattedValue}%`}
                    enableChip={true}
                    color={datum.color}
                  />
                }}
                data={data2}
                margin={{ top: 70, right: 70, bottom: 140, left: -100 }}
                innerRadius={0.4}
                enableArcLinkLabels={false}
                padAngle={1}
                cornerRadius={5}
                enableArcLabels={false}
                theme={{ legends: { text: { fontSize: 14, fontFamily: "Geomanist" } } }}
                activeOuterRadiusOffset={8}
                legends={[
                  {
                    anchor: 'right',
                    direction: 'column',
                    justify: false,
                    translateX: -10,
                    translateY: 0,
                    itemsSpacing: 0,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#464868',
                    itemDirection: 'left-to-right',
                    itemOpacity: 1,
                    symbolSize: 16,
                    symbolShape: 'square',
                  }
                ]}
              />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RicettaDetails;
