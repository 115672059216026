import React, { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import IngredientSearchBar from "./IngredientSearchBar";

const IngredientAndQuantity = ({
  ingredients = null,
  quantity,
  setIngredients = null,
  setQuantity,
  isMealItem = false,
  isMainMealItem = true,
  mealItemsIndex = null,
  setIsAddIngredientAlternative = null,
  title = "Aggiungi Ingrediente",
  handleAddClick = null,
  unit = "g",
  setUnit = null
}) => {
  const [error, setError] = useState("")
  const [ingredientSelected, setIngredientSelected] = useState(null);
  const handleAddIngredientAlternative = (mealItemIndex) => {
    if (!ingredientSelected || !quantity) {
      setError("Seleziona un ingrediente e la sua quantità")
      return
    }
    const newIngredient = {
      ingredient: ingredientSelected,
      quantity: Number(quantity),
      selected: isMainMealItem,
      unity: unit,
    };
    const ingredientsCopy = [...ingredients];
    const ingredientOptionsCopy = [...ingredientsCopy[mealItemIndex]]
    ingredientOptionsCopy.push(newIngredient)
    ingredientsCopy[mealItemIndex] = ingredientOptionsCopy
    setIngredients(ingredientsCopy);
    setIngredientSelected(null);

    setQuantity("");
    setIsAddIngredientAlternative(null);
  };
  const handleAddIngredientClick = () => {
    if (!ingredientSelected || !quantity) {
      setError("Seleziona un ingrediente e la sua quantità")
      return
    }
    setError("")
    const newIngredient = {
      ingredient: ingredientSelected,
      quantity: Number(quantity),
      unity: unit,
    };
    if(isMealItem){
      newIngredient.selected = isMainMealItem
      setIngredients([...ingredients, [newIngredient]]);
    }
    else {
      setIngredients([...ingredients, newIngredient]);
    }
    setIngredientSelected(null);

    setQuantity("");
  };
  const handleSelectIngrediente = (ingredient) => {
    setIngredientSelected(ingredient);
  };
  const handleAddClickGeneral = () => {
    if (handleAddClick) {
      handleAddClick(ingredientSelected)
    }
    else if (isMainMealItem) {
      handleAddIngredientClick()
    }
    else {
      handleAddIngredientAlternative(mealItemsIndex)
    }
  }
  return (
    <Box>
      <Box
        mt="20px"
        sx={{
          borderRadius: "6px",
          border: "1px solid rgba(193, 199, 205, 1)",
        }}
      >
        <Typography
          color="#000"
          fontSize="20px"
          fontWeight="700"
          variant="h1"
          sx={{ m: "1.5rem" }}
        >
          {title}
        </Typography>
        <Box display="flex" justifyContent="space-between" ml="1.5rem">
          <Box display="flex" gap="1rem">
            <Box sx={{ minWidth: "250px" }} >
              <IngredientSearchBar
                id="ingredients"
                getOptionLabel={(option) => `${option.simple_name_it} ${option.specific_info ? option.specific_info : ""}`}
                onChange={(event, newValue) => handleSelectIngrediente(newValue)}
                value={ingredientSelected} 
                // renderTags={(value, getTagProps) =>
                //   value.map((option, index) => (
                //     <Chip
                //       style={{
                //         backgroundColor: 'rgba(70, 72, 104, 1)',
                //         color: "white",
                //         fontWeight: "500",
                //         borderRadius: "4px"
                //       }}
                //       color="info"
                //       variant="filled"
                //       label={option.name_it}
                //       size="small"
                //       {...getTagProps({ index })}
                //     />
                //   ))
                // }
                label="Cerca ingrediente"
              />
            </Box>
            <FormControl variant="filled" sx={{ minWidth: "100px" }}>
              <TextField
                value={quantity}
                id="qt"
                label={setUnit ? "Quantità" : "Quantità (g)"}
                variant="filled"
                color="secondary"
                type="number"
                onChange={(event) => setQuantity(event.target.value)}
              />
            </FormControl>
            {setUnit && <FormControl variant="filled" sx={{ minWidth: "100px" }}>
              <InputLabel id="unit-select-label" color="secondary">
                Unità
              </InputLabel>
              <Select
                labelId="unit-select"
                id="selectUnit"
                label="Unità"
                value={unit}
                onChange={(event) => setUnit(event.target.value)}
                color="secondary"
              >
                <MenuItem value={"g"}>g</MenuItem>
                <MenuItem value={"ml"}>ml</MenuItem>
                <MenuItem value={"cucchiaio"}>cucchiaio</MenuItem>
                <MenuItem value={"cucchiaino"}>cucchiaino</MenuItem>
                <MenuItem value={"bicchiere"}>bicchiere</MenuItem>
                <MenuItem value={"tazza"}>tazza</MenuItem>
                <MenuItem value={"vasetto"}>vasetto</MenuItem>
                <MenuItem value={"frutto medio"}>frutto medio</MenuItem>
                <MenuItem value={"manciata"}>manciata</MenuItem>
                <MenuItem value={"pugno"}>pugno</MenuItem>
                <MenuItem value={"fetta"}>fetta</MenuItem>
                <MenuItem value={"palmo"}>palmo</MenuItem>
                <MenuItem value={"dito"}>dito</MenuItem>
                <MenuItem value={"biscotto"}>biscotto</MenuItem>
                <MenuItem value={"fetta biscottata"}>fetta biscottata</MenuItem>
                <MenuItem value={"patata"}>patata</MenuItem>
                <MenuItem value={"uovo"}>uovo</MenuItem>
                <MenuItem value={"piatto"}>piatto</MenuItem>
              </Select>
            </FormControl>}
          </Box>
          <Box m="1rem" display="flex" gap="1rem">
            <div className="gradient-border-wrapper">
              <Button
                sx={{
                  color: "linear-gradient(to top right, #B0D99C, #68BE6A)",
                  fontWeight: 600,
                  textTransform: 'none',
                  width: "100%", // Ensure the button takes up the full width
                }}
                className="gradient-button"
                startIcon={<AddIcon style={{ color: '#68BE6A' }} />}
                onClick={handleAddClickGeneral}
              >
                Aggiungi
              </Button>
            </div>
          </Box>
        </Box>
        {error && <Box ml="24px"><p style={{ color: 'red' }}>{error}</p></Box>}
      </Box>
      {ingredients && ingredients.length === 0 && <p style={{ color: "red" }}>Campo obbligatorio</p>}
    </Box>
  );
}

export default IngredientAndQuantity;
